import React, { useState, useEffect } from 'react';
import { Button, Paper, Typography, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import spamWordData from './spamWordData.json';
import MouseOverPopover from './MouseOverPopover';
import PropTypes from 'prop-types';
import { useEmailContext } from './EmailContext';

const Content = ({ onMessageScore }) => {
  const { selectedEmail } = useEmailContext();
  const displayName = selectedEmail?.displayName || '';
  const senderEmailDisplayName = selectedEmail?.senderEmailDisplayName || '';
  const emailBody = selectedEmail?.emailBody || '';
  const spamWords = spamWordData.words || [];

  const [receiverScore, setReceiverScore] = useState(0);
  const [dictScore, setDictScore] = useState(0);
  const [spamWordCount, setSpamWordCount] = useState(0);
  const [nameCount, setNameCount] = useState(0);
  const [totVal, setTotVal] = useState(0);
  const [foundWords, setFoundWords] = useState([]);

  // Function to determine progress bar style based on value
  const getBarStyle = (value) => {
    let backgroundColor = '';
    if (value <= 25) backgroundColor = 'red';
    else if (value <= 50) backgroundColor = 'OrangeRed';
    else if (value <= 75) backgroundColor = 'orange';
    else backgroundColor = 'green';
  
    return {
      width: `${value}%`, // Set width based on the score value
      backgroundColor,
    };
  };

  useEffect(() => {
    if (senderEmailDisplayName && emailBody && displayName) {
      const user = displayName.toLowerCase();
      const nameArray = user.split(' ').filter(Boolean);
      let ncount = 0;

      const bodyString = emailBody.replace(/[\t\n\r]/gm, ' ').toLowerCase();

      // Check each word in displayName against the bodyString
      nameArray.forEach((user) => {
        // Escape special regex characters in the name
        const escapedName = user.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(`\\b${escapedName}\\b`, 'gi');
        const matches = bodyString.match(regex);
        if (matches) ncount += matches.length;
      });

      let currentReceiverScore = 0;

      if (senderEmailDisplayName.toLowerCase() === displayName.toLowerCase()) {
        currentReceiverScore = 100;
      } else if (bodyString.includes(user)) {
        currentReceiverScore = 10;
      } else {
        currentReceiverScore = Math.min(100, ncount * 30);
      }

      setReceiverScore(currentReceiverScore);
      setNameCount(ncount);
    }
  }, [senderEmailDisplayName, emailBody, displayName]);

  useEffect(() => {
    if (emailBody) {
      let scount = 0;
      const foundWordsSet = new Set(); // Store unique found spam words

      const normalizedBody = emailBody.toLowerCase();

      spamWords.forEach((word) => {
        const escapedWord = word
          .toLowerCase()
          .replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

        const regex =
          word.includes(' ') || /[^a-zA-Z0-9]/.test(word)
            ? new RegExp(escapedWord, 'gi')
            : new RegExp(`\\b${escapedWord}\\b`, 'gi');

        const matches = normalizedBody.match(regex);
        if (matches) {
          scount += matches.length; // Count all occurrences
          foundWordsSet.add(word);
        }
      });

      setSpamWordCount(scount); // Update spam word count
      setFoundWords(Array.from(foundWordsSet)); // Update list of found spam words
      const newDictScore = Math.max(0, 100 - scount * 2); // Calculate new score
      setDictScore(newDictScore);
    }
  }, [emailBody, spamWords]);

  useEffect(() => {
    if (receiverScore !== null && dictScore !== null) {
      const value = Math.round((receiverScore + dictScore) / 2);
      const roundedScore = Math.max(0, Math.min(100, value));
      setTotVal(roundedScore);

      // Pass the rounded score to the message callback if it exists
      if (onMessageScore) {
        onMessageScore(roundedScore);
      }
    }
  }, [receiverScore, dictScore, onMessageScore]);

  return (
    <Box sx={{ padding: '1em', marginTop: '1em' }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {/* Receiver */}
        <Paper
          sx={{
            padding: 2,
            maxWidth: '85%',
            marginTop: '.6em',
            backgroundColor: 'WhiteSmoke',
          }}
        >
          <Box
            sx={{
              width: '90%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Receiver</Typography>
            <MouseOverPopover
              tekst="Phishing emails are usually anonymous and do not address the receiver by name.
                  They typically do not contain any proof of relation, such as knowing the name of the receiver."
            />
          </Box>
          <Box
            sx={{
              marginY: 1,
              backgroundColor: '#e0e0e0',
              borderRadius: 1,
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                height: 30,
                color: '#fff',
                textAlign: 'center',
                lineHeight: '30px',
                ...getBarStyle(receiverScore),
              }}
            >
              <b>{`${receiverScore}%`}</b>
            </Box>
          </Box>
          <Typography>
            First or last name of <b>{displayName}</b> was mentioned{' '}
            <b>{nameCount}</b> time(s) in this email.
          </Typography>
        </Paper>

        {/* Phishing Words */}
        <Paper
          sx={{
            padding: 2,
            maxWidth: '85%',
            marginTop: '.6em',
            backgroundColor: 'WhiteSmoke',
          }}
        >
          <Box
            sx={{
              width: '90%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">Phishing words</Typography>
            <MouseOverPopover
              tekst="Phishing emails often contain certain words related to urgency, fear, username/password changes, or 
                  economic loss/gain. These emails try to trick recipients into providing personal information."
            />
          </Box>
          <Box
            sx={{
              marginY: 1,
              backgroundColor: '#e0e0e0',
              borderRadius: 1,
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                height: 30,
                color: '#fff',
                textAlign: 'center',
                lineHeight: '30px',
                ...getBarStyle(dictScore),
              }}
            >
              <b>{`${dictScore}%`}</b>
            </Box>
          </Box>
          <Typography>
            Phishing words found: <b>{spamWordCount}</b>
          </Typography>
          <Typography>Words:</Typography>
          <Box sx={{ padding: 1 }}>
            {foundWords.map((item) => (
              <Typography key={item} variant="body2">
                <b>{item}</b>
              </Typography>
            ))}
          </Box>
        </Paper>

        {/* Evaluation */}
        
      </Box>
      </Box>
  );
};

Content.propTypes = {
  onMessageScore: PropTypes.func,
};

export default Content;
