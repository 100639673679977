import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import { Auth0Provider } from '@auth0/auth0-react';
//import Auth0History from './Auth0Provider';
//import { Auth0Provider } from '@auth0/auth0-react'; // Renamed to avoid conflict
import { EmailProvider } from './EmailContext';
import Dashboard from './Dashboard';
// Removed the duplicate Auth0Provider import

const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;

const root = ReactDOM.createRoot(document.getElementById('root'));
console.log(domain);
console.log(clientId);



root.render(
   <BrowserRouter>
   <Auth0Provider
      domain='whatthephish.us.auth0.com'
      clientId='pfMI5SkX9suOgWU99rE9msa5NB88mjhN'
      authorizationParams={{
        redirect_uri: window.location.origin + '/Report',
        scope: 'openid profile email',
      }}
    >
      <Dashboard>
      <ThemeProvider theme={theme}>
      <EmailProvider>
          <App />
          </EmailProvider>
        </ThemeProvider>
        </Dashboard>
          </Auth0Provider>
    </BrowserRouter>

);
