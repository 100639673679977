import React, { useEffect, useState } from 'react';
import {
    Container,
    Grid2,
    Typography,
    Paper,
    CircularProgress,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from '@mui/material';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Tooltip,
  Legend,
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Tooltip,
  Legend
);

const Report = () => {
  const [userClassifications, setUserClassifications] = useState(null);
  const [totalCount, setTotalCount] = useState(null);
  const [phishingNames, setPhishingNames] = useState(null);
  const [recipients, setRecipients] = useState(null);
  const [datesSent, setDatesSent] = useState(null);
  const [dayOfWeek, setDayOfWeek] = useState(null);
  const [senderReceiver, setSenderReceiver] = useState(null);
  const [timeOfDay, setTimeOfDay] = useState(null);
  const [targetDayAndTime, setTargetDayAndTime] = useState(null);

  useEffect(() => {
    // Fetch the data from the API
    const fetchReportData = async () => {
      try {
        const res = await fetch(`/api/reports`);
        const data = await res.json();

        // Parse data to match the expected format for charts
        setTotalCount([
          {
            label: 'Total Observations',
            value: data.totalCount[0].total_observations,
          },
        ]);
        
        setUserClassifications(
          data.analyzedCount.map((item) => ({
            user: item.emailAddress,
            total_emails: item.total_analyzed_emails,
            phishing_count: item.phishing_count,
            legitimate_count: item.legitimate_count,
          }))
        )
        setPhishingNames(
          data.phishingNames.map((item) => ({
            name: item.senderEmailDisplayName,
            count: item.total,
          }))
        );

        setRecipients(
          data.recipients.map((item) => ({
            email: item.emailAddress,
            count: item.total_received,
          }))
        );

        setDatesSent(
          data.datesSent.map((item) => ({
            date: item.date_sent,
            count: item.emails_per_day,
          }))
        );

        setDayOfWeek(
          data.dayOfWeek.map((item) => ({
            day: item.day_of_week,
            count: item.count,
          }))
        );

        setSenderReceiver(
          data.senderReceiver.map((item) => ({
            sender: item.senderEmailEmailAddress,
            receiver: item.emailAddress,
            count: item.total,
          }))
        );

        setTimeOfDay(
          data.timeOfDay.map((item) => ({
            hour: item.hour,
            count: item.count,
          }))
        );

        setTargetDayAndTime(
          data.targetDayAndTime.map((item) => ({
            day: item.date_time,
            hour: item.time,
            count: item.count,
          }))
        );
      } catch (error) {
        console.error(`Error fetching report data:`, error);
      }
    };

    fetchReportData();
  }, []);

  // Early return if data is not yet available
  if (
      !totalCount ||
      !phishingNames ||
      !recipients ||
      !datesSent ||
      !dayOfWeek ||
      !senderReceiver ||
      !timeOfDay ||
      !targetDayAndTime ||
      !userClassifications
  ) {
    return (
      <Container>
        <CircularProgress />
      </Container>
    );
  }

  // Chart data configurations

  const totalCountChartData = {
    labels: totalCount.map((item) => item.label),
    datasets: [
      {
        label: 'Total Count',
        data: totalCount.map((item) => item.value),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
      },
    ],
  };

 const recipientsChartData = {
   labels: recipients.map((item) => item.email), // Corrected property name
   datasets: [
     {
       label: 'Emails Received',
       data: recipients.map((item) => item.count), // Corrected property name
       backgroundColor: 'rgba(54, 162, 235, 0.6)',
      borderColor: 'rgba(54, 162, 235, 1)',
       borderWidth: 1,
     },
   ],
  };

 const recipientsChartOptions = {
   scales: {
     y: {
       beginAtZero: true,
     },
   },
 };

 const phishingNamesChartData = {
   labels: phishingNames.map((item) => item.name),
   datasets: [
     {
       label: 'Sender Names',
       data: phishingNames.map((item) => item.count),
       backgroundColor: 'rgba(255, 99, 132, 0.6)',
     },
   ],
 };

 //const dayOfWeekChartData = {
 //  labels: dayOfWeek.map((item) => item.day),
 //  datasets: [
  //   {
 //      label: 'Emails Sent by Day of the Week',
 //      data: dayOfWeek.map((item) => item.count),
  //     backgroundColor: 'rgba(153, 102, 255, 0.6)',
 //    },
  // ],
 ///};

  const senderReceiverChartData = {
    labels: senderReceiver.map(
      (item) => `${item.sender} → ${item.receiver}`
    ),
    datasets: [
      {
       label: 'Sender-Receiver Relationships',
        data: senderReceiver.map((item) => item.count),
      backgroundColor: 'rgba(54, 162, 235, 0.6)',
      },
    ],
  };
 //const totalEmailsPerUserChartData = {
  //labels: userClassifications.map((item) => item.user),
//  datasets: [
   // {
   //   label: 'Total Emails Submitted',
   //   data: userClassifications.map((item) => item.total_emails),
 //     backgroundColor: 'rgba(75, 192, 192, 0.6)',
  //  },
 // ],
//};

const classificationCountsChartData = {
  labels: userClassifications.map((item) => item.user),
  datasets: [
    {
      label: 'Phishing',
      data: userClassifications.map((item) => item.phishing_count),
      backgroundColor: '#36a2eb',
    },
    {
      label: 'Legitimate',
      data: userClassifications.map((item) => item.legitimate_count),
      backgroundColor: '#ffcd56',
    },
  ],
};

const classificationCountsChartOptions = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      beginAtZero: true,
    },
  },
  plugins: {
    title: {
      display: true,
      text: 'Classification Counts per User',
    },
  },
};

 const timeOfDayChartData = {
   labels: timeOfDay.map((item) => item.hour),
   datasets: [
     {
       label: 'Emails Sent by Time of Day',
       data: timeOfDay.map((item) => item.count), // Corrected property name
       backgroundColor: 'rgba(75, 192, 192, 0.6)',
     },
   ],
 };

  const targetDayAndTimeChartData = {
   labels: targetDayAndTime.map(
     (item) => `${item.day}, Hour of Day ${item.hour}`
   ),
   datasets: [
    {
       label: 'Day and Time Analysis Submitted',
       data: targetDayAndTime.map((item) => item.count),
       backgroundColor: 'rgba(153, 102, 255, 0.6)',
     },
   ],
 };

 return (
  <Container>
    <Grid2 container spacing={2}>
    <Grid2 size={12} >
    <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
    <Typography variant="h4" component="h1" gutterBottom>
      Report Overview
    </Typography>
    </Paper>
    </Grid2>

    
      {/* Total Observations Chart */}
      <Grid2 size={6} >
      <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
          <Typography variant="h6">Total Observations</Typography>
          <Bar data={totalCountChartData} />
        </Paper>
      </Grid2>

      {/* Emails Submitted per User Chart */}
      <Grid2 size={6} >
        <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
          <Typography variant="h6">Emails Submitted per User</Typography>
          <Bar data={recipientsChartData} options={recipientsChartOptions} />
        </Paper>
      </Grid2>

      {/* Time of Day Chart */}
      <Grid2 size={6} >
        <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
          <Typography variant="h6">Time of Day</Typography>
          <Bar data={timeOfDayChartData} />
        </Paper>
      </Grid2>

      {/* Time and Day of Week Chart */}
      <Grid2 size={6} >
        <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
          <Typography variant="h6">Time and Day of Week</Typography>
          <Bar data={targetDayAndTimeChartData} />
        </Paper>
      </Grid2>

      {/* Names of Sender Chart */}
      <Grid2 size={6} >
        <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
          <Typography variant="h6">Names of Sender</Typography>
          <Bar data={phishingNamesChartData} />
        </Paper>
      </Grid2>

      <Grid2 size={6} >
        <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
          <Typography variant="h6">Classification Counts per User</Typography>
          <Bar
            data={classificationCountsChartData}
            options={classificationCountsChartOptions}
          />
        </Paper>
      </Grid2>

      {/* Sender Emails Per Receiver Email Table */}
      <Grid2 size={12} >
        <Paper elevation={3} sx={{ p: 2, textAlign: 'center' }}>
          <Typography variant="h6">Sender Emails Per Receiver Email</Typography>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Sender</TableCell>
                  <TableCell>Receiver</TableCell>
                  <TableCell align="right">Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {senderReceiver.map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.sender}</TableCell>
                    <TableCell>{item.receiver}</TableCell>
                    <TableCell align="right">{item.count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid2>

      {/* Classification Counts per User Chart */}
      
    </Grid2>
  </Container>
);
};

export default Report;
