import React, { createContext, useState, useContext, useEffect } from 'react';

// Create a context for email selection
const EmailContext = createContext();

// Provider component to wrap the app
export const EmailProvider = ({ children }) => {
  const [selectedEmail, setSelectedEmail] = useState(null);
  const [emailList, setEmailList] = useState([]);

  // Fetch email list
  const fetchEmailList = async () => {
    try {
      const response = await fetch('/email_list'); // Replace with actual endpoint
      const data = await response.json();
      setEmailList(data);
    } catch (error) {
      console.error('Error fetching email list:', error);
    }
  };

  // Fetch email details
  const fetchEmailDetails = async (id) => {
    try {
      const response = await fetch('/emails_selects', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id }),
      });
      const data = await response.json();
      setSelectedEmail(data);
    } catch (error) {
      console.error('Error fetching email details:', error);
    }
  };

  // Fetch the list of emails when the context is initialized
  useEffect(() => {
    fetchEmailList();
  }, []);

  return (
    <EmailContext.Provider value={{ selectedEmail, emailList, fetchEmailList, fetchEmailDetails }}>
      {children}
    </EmailContext.Provider>
  );
};

// Custom hook to use the email context
export const useEmailContext = () => {
  return useContext(EmailContext);
};
