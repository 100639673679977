import React, { useState } from 'react';
import { useEmailContext } from './EmailContext';
import { List, ListItem, ListItemText, Paper, Box, Typography, Checkbox, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const EmailSelectionPage = () => {
  const { emailList, fetchEmailDetails } = useEmailContext();
  const [selectedEmails, setSelectedEmails] = useState([]);
  const navigate = useNavigate();

  // Handle checkbox selection
  const handleToggle = (emailId) => {
    setSelectedEmails((prevSelected) =>
      prevSelected.includes(emailId)
        ? prevSelected.filter((id) => id !== emailId)
        : [...prevSelected, emailId]
    );
  };

  // Handle "Go" button click to navigate to MainList
  const handleGo = () => {
    // Fetch details for selected email(s) or other processing as needed
    selectedEmails.forEach((emailId) => {
      fetchEmailDetails(emailId);
    });
    
    // Navigate to MainList (assuming MainList is set up in your routing configuration)
    navigate('/MainList');
  };

  return (
    <Box
      sx={{ padding: 3 }}
    >
      <Typography variant="h5" gutterBottom>
        Select an Email
      </Typography>
      <Paper sx={{ padding: 2 }}>
        <List>
          {emailList.length > 0 ? (
            emailList.map((item) => (
              <ListItem
                key={item.id}
                sx={{
                  width: '100%',
                  textAlign: 'left',
                  padding: { xs: '10px', sm: '15px' },
                  borderRadius: 1,
                  transition: 'background-color 0.3s ease',
                }}
                secondaryAction={
                  <Checkbox
                    edge="end"
                    onChange={() => handleToggle(item.id)}
                    checked={selectedEmails.includes(item.id)}
                  />
                }
              >
                <ListItemText
                  primary={item.senderEmailDisplayName}
                  secondary={item.senderEmailEmailAddress}
                />
              </ListItem>
            ))
          ) : (
            <Typography variant="body1" color="text.secondary">
              No emails available
            </Typography>
          )}
        </List>
        {/* "Go" Button */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleGo}
          disabled={selectedEmails.length === 0}
          sx={{ marginTop: 2 }}
        >
          Go
        </Button>
      </Paper>
    </Box>
  );
};

export default EmailSelectionPage;
