// MainList.js

import React, { useState } from 'react';
import {
  Typography,
  Grid2,
  Card,
  CardContent,
  CardHeader
} from '@mui/material';
import Authentication from './Authentication';
import Content from './Content';
import Sender from './Sender';
import LinkComponent from './Links';
//import EmailStatistics from './EmailStatistics';
import MouseOverPopover from './MouseOverPopover';
import EmailSelectionPage from './EmailSelectionPage';

const MainList = () => {
  const [sendScore, setSendScore] = useState(0);
  const [messageScore, setMessageScore] = useState(0);
  const [linkScore, setLinkScore] = useState(0);
  const [authScore, setAuthScore] = useState(0);
  const [totVal, setTotVal] = useState(0);
  const [safetyType, setSafetyType] = useState('');

  // Handler functions
  const handleAuth = (authScore) => {
    setAuthScore(authScore);
    calculateTotalEvaluation();
  };

  const handleMessage = (messageScore) => {
    setMessageScore(messageScore);
    calculateTotalEvaluation();
  };

  const handleSender = (sendScore) => {
    setSendScore(sendScore);
    calculateTotalEvaluation();
  };

  const handleLink = (linkScore) => {
    setLinkScore(linkScore);
    calculateTotalEvaluation();
  };

  const calculateTotalEvaluation = () => {
    const scores = [authScore, messageScore, sendScore, linkScore];
    const validScores = scores.filter(
      (score) => score !== null && score !== undefined
    );
    if (validScores.length === 0) return;

    const total = validScores.reduce((acc, score) => acc + score, 0);
    const average = total / validScores.length;
    const roundedScore = Math.round(average);

    setTotVal(roundedScore);
    determineSafetyLevel(roundedScore);
  };

  const determineSafetyLevel = (score) => {
    let safety = '';
    if (score <= 25) {
      safety = 'NO EMAIL SAFETY';
    } else if (score > 25 && score <= 50) {
      safety = 'LOW EMAIL SAFETY';
    } else if (score > 50 && score <= 75) {
      safety = 'MEDIUM EMAIL SAFETY';
    } else if (score > 75) {
      safety = 'HIGH EMAIL SAFETY';
    }
    setSafetyType(safety);
  };

  return (
    
    <Card>
      {/* Title of the Dashboard */}
      <CardHeader
        title={
          <Typography variant="h4" component="h1" gutterBottom>
            Email Analysis Dashboard
          </Typography>
        }
      />
         <EmailSelectionPage />
      {/* Container Card for All Inner Cards */}
      <Card
        sx={{
          padding: '1em',
          marginTop: '1em',
          backgroundColor: '#f5f5f5',
          borderRadius: '8px',
        }}
      >
        {/* Overall Evaluation Card */}
        <Card>
          <CardHeader
            title="Overall Evaluation"
            action={
              <MouseOverPopover tekst="Overall assessment based on all components." />
            }
          />
          <CardContent>
            <Typography variant="h6">Total Score: {totVal}%</Typography>
            <Typography variant="h6">Safety Level: {safetyType}</Typography>
            {/* Include progress bar or additional info if desired */}
          </CardContent>
        </Card>

        {/* Grid2 for Dashboard Components */}
        <Grid2>
          {/* Sender Component Card */}
          <Grid2 item xs={11} sm={11} md={11}>
            <Card sx={{ height: '100%' }}>
              <CardHeader
                title="Sender Analysis"
                action={
                  <MouseOverPopover
                    tekst="Analysis of the sender's information and potential risks."
                  />
                }
              />
              <CardContent>
                <Sender onSenderScore={handleSender} />
              </CardContent>
            </Card>
          </Grid2>

          {/* Content Component Card */}
          <Grid2 >
            <Card sx={{ height: '100%' }}>
              <CardHeader
                title="Content Analysis"
                action={
                  <MouseOverPopover
                    tekst="Analysis of the email content for suspicious elements."
                  />
                }
              />
              <CardContent>
                <Content onMessageScore={handleMessage} />
              </CardContent>
            </Card>
          </Grid2>
          
          <Grid2 item xs={11} sm={11} md={11}>
            <Card sx={{ height: '100%' }}>
              <CardHeader
                title="Authentication Analysis"
                action={
                  <MouseOverPopover
                    tekst="Verification of email authentication mechanisms."
                  />
                }
              />
              <CardContent>
                <Authentication onAuthScore={handleAuth} />
              </CardContent>
            </Card>
          </Grid2>
          {/* Links Component Card */}
          <Grid2 >
            <Card sx={{ height: '100%' }}>
              <CardHeader
                title="Links Analysis"
                action={
                  <MouseOverPopover
                    tekst="Analysis of links contained in the email."
                  />
                }
              />
              <CardContent>
                <LinkComponent onLinkScore={handleLink} />
              </CardContent>
            </Card>
          </Grid2>

          {/* Authentication Component Card */}
          

          {/* Email Statistics Component Card */}
          
        </Grid2>
      </Card>
    </Card>
    
  );
};

export default MainList;
