// src/components/AuthButtons.js
import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';

const AuthButtons = () => {
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();

  return (
    <div>
      {!isAuthenticated ? (
        <Button color="inherit" onClick={() => loginWithRedirect()}>
          Login
        </Button>
      ) : (
        <Button
          color="inherit"
          onClick={() =>
            logout({
              returnTo: window.location.origin,
            })
          }
        >
          Logout
        </Button>
      )}
    </div>
  );
};

export default AuthButtons;
