import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Paper
} from '@mui/material';
import { Link } from 'react-router-dom';
import MouseOverPopover from './MouseOverPopover';
import PropTypes from 'prop-types';
import { useEmailContext } from './EmailContext';
import sharedStyles from './sharedStyles';

const Authentication = ({ onAuthScore }) => {
  const [SPF, setSPF] = useState('');
  const [DKIM, setDKIM] = useState('');
  const [DMARC, setDMARC] = useState('');
  const [spfScore, setSpfScore] = useState(0);
  const [dkimScore, setDkimScore] = useState(0);
  const [dmarcScore, setDmarcScore] = useState(0);
  //const [totVal, setTotVal] = useState(0);
  const [spfText, setSpfText] = useState('');
  const [dkimText, setDkimText] = useState('');
  const [dmarcText, setDmarcText] = useState('');
  const [ipAdr, setIpAdr] = useState('');
  const [ipURL, setIpURL] = useState('');
  const [barStyles, setBarStyles] = useState({
    bar_1: {},
    bar_2: {},
    bar_3: {},
    bar_4: {}
  });
  
  const { selectedEmail } = useEmailContext();

  // Destructure selectedEmail if available
  const displayName = selectedEmail?.displayName;
  const senderEmailDisplayName = selectedEmail?.senderEmailDisplayName;
  const mailHeaders = selectedEmail?.mailHeaders;

  
  useEffect(() => {
    if (senderEmailDisplayName && mailHeaders && displayName) {
      internetHeaders(senderEmailDisplayName, mailHeaders, displayName);
    }
    
  }, [senderEmailDisplayName, mailHeaders, displayName]);


  useEffect(() => {
    if (mailHeaders) {
      extractSenderIP(mailHeaders);
      analyzeAuth(mailHeaders);
    }
}, [mailHeaders]);

  // Main function to handle header analysis
const internetHeaders = (senderEmailDisplayName, mailHeaders, displayName) => {
  if (senderEmailDisplayName === displayName) {
    setSPF('Pass');
    setDKIM('pass');
    setDMARC('pass');
   // setTotVal(100);
    scoreSPF('pass');
    scoreDKIM('pass');
    scoreDMARC('pass');
    totEvaluation(100, 100, 100);
  } else {
    analyzeAuth(mailHeaders);
  }
};

// Analyze SPF, DKIM, and DMARC from mail headers
const analyzeAuth = (mailHeaders) => {
  // SPF
  const SPF = 'spf=';
  const splitt_SPF = mailHeaders.split(SPF).pop();
  const val_1 = splitt_SPF.match(/none|pass|neutral|fail|softfail|permerror|temperror/g)?.[0] || 'none';
  
  // DKIM
  const DKIM = 'dkim=';
  const splitt_DKIM = mailHeaders.split(DKIM).pop();
  const val_2 = splitt_DKIM.match(/none|pass|fail/g)?.[0] || 'none';
  
  // DMARC
  const DMARC = 'dmarc=';
  const splitt_DMARC = mailHeaders.split(DMARC).pop();
  const val_3 = splitt_DMARC.match(/none|pass|bestguesspass|fail/g)?.[0] || 'none';

  setSPF(val_1);
  setDKIM(val_2);
  setDMARC(val_3);

  // Calculate and score authentication
  const spfScore = scoreSPF(val_1);
  const dkimScore = scoreDKIM(val_2);
  const dmarcScore = scoreDMARC(val_3);
  totEvaluation(spfScore, dkimScore, dmarcScore);
};

// SPF Scoring Function
const scoreSPF = (val_1) => {
  let spfScore = 0;
  let spfText = '';

  switch (val_1.toLowerCase()) {
    case 'pass':
      spfScore = 100;
      spfText = 'The SPF record designates the host to be allowed to send.';
      break;
    case 'softfail':
      spfScore = 50;
      spfText = 'The SPF record has designated the host as NOT being allowed to send but is in transition.';
      break;
    case 'neutral':
      spfText = 'The SPF record specifies explicitly that nothing can be said about validity.';
        break;
    case 'none':
      spfScore = 25;
      spfText = 'The domain does not have an SPF record or the SPF record does not evaluate to a result.';
      break;
    case 'fail':
      spfText = 'The SPF record has designated the host as NOT being allowed to send.';
        break;
    case 'permerror':
      spfText = 'A permanent error has occurred.';
        break;
    case 'temperror':
      spfScore = 0;
      spfText = 'A transient error has occurred.';
      break;
    default:
      break;
  }

  setSpfScore(spfScore);
  progBar(spfScore, 'bar_1');
  setSpfText(spfText);
  return spfScore;
};

// DKIM Scoring Function
const scoreDKIM = (val_2) => {
  let dkimScore = 0;
  let dkimText = '';

  switch (val_2.toLowerCase()) {
    case 'pass':
      dkimScore = 100;
      dkimText = 'The email has DKIM Signature and passed the verification check.';
      break;
    case 'fail':
      dkimScore = 25;
      dkimText = 'The email message has a DKIM signature but there was an error causing a verification failure.';
      break;
    case 'none':
      dkimScore = 0;
      dkimText = 'The email message has not been signed with DKIM so there is nothing to verify.';
      break;
    default:
      break;
  }

  setDkimScore(dkimScore);
  progBar(dkimScore, 'bar_2');
  setDkimText(dkimText);
  return dkimScore;
};

// DMARC Scoring Function
const scoreDMARC = (val_3) => {
  let dmarcScore = 0;
  let dmarcText = '';

  switch (val_3.toLowerCase()) {
    case 'pass':
      dmarcScore = 100;
      dmarcText = 'Email is authenticated against established DKIM and SPF standards.';
      break;
    case 'bestguesspass':
      dmarcScore = 50;
      dmarcText = 'Either SPF or DKIM failed to authenticate or the email body from address did not align with the domain.';
      break;
    case 'none':
      dmarcText = 'Email is NOT authenticated against established DKIM and SPF standards.';
      break;
    case 'fail':
      dmarcScore = 0;
      dmarcText = 'Email failed to authenticate.';
      break;
    default:
      break;
  }

  setDmarcScore(dmarcScore);
  progBar(dmarcScore, 'bar_3');
  setDmarcText(dmarcText);
  return dmarcScore;
};

const extractSenderIP = (mailHeaders) => {
  const ipRegex = /\b\d{1,3}(?:\.\d{1,3}){3}\b/;
  const ipMatch = mailHeaders.match(ipRegex);

  if (ipMatch) {
    const ipAddress = ipMatch[0];
    const url = `https://mxtoolbox.com/SuperTool.aspx?action=blacklist%3a${ipAddress}&run=toolpage`;
    setIpAdr(ipAddress);
    setIpURL(url);
  } else {
    setIpAdr('IP address not found');
  }
};

const progBar = (value, barKey) => {
  // Determine background color based on the value
  let backgroundColor = 'red';
if (value >= 75) backgroundColor = 'green';
else if (value >= 50) backgroundColor = 'orange';
else if (value >= 25) backgroundColor = 'Orangered';

  // Update the bar styles based on barKey
  setBarStyles((prevStyles) => ({
    ...prevStyles,
    [barKey]: { width: '100%', backgroundColor }
  }));
};


// Total Evaluation
const totEvaluation = (spfScore, dkimScore, dmarcScore) => {
  const totalScore = (spfScore + dkimScore + dmarcScore) / 3; // Average the scores
  const roundedScore = Math.round(totalScore);
 // setTotVal(roundedScore);
  progBar(roundedScore, 'bar_4'); // Total progress bar

  // Call onMessageScore if exists
  if (onAuthScore) {
    onAuthScore(roundedScore);
  }
};


return (
    <Box container spacing={2} alignItems="stretch">
      {/* SPF Card */}
      <Paper  sx={{
            padding: 2,
            maxWidth: '85%',
            marginTop: '.6em',
            backgroundColor: 'WhiteSmoke',
          }}>
        <Box
          sx={{
              width: '90%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
        >
          <Typography variant="h6">SPF</Typography>
                         <MouseOverPopover
                tekst="Sender Policy Framework (SPF) allows the receiving mail server to verify the sender's IP address."
              />    </Box>
          <Box
            sx={{
              marginY: 1,
              backgroundColor: '#e0e0e0',
              borderRadius: 1,
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                height: 30,
                color: '#fff',
                textAlign: 'center',
                lineHeight: '30px',
                ...barStyles.bar_1,
              }}
            >
                <b>{spfScore}%</b>
              </Box>
          </Box>
            <Typography>
              SPF: <b>{SPF}</b>
            </Typography>
            <Typography>{spfText}</Typography>
      </Paper>


      {/* DKIM Card */}
        <Paper  sx={{
            padding: 2,
            maxWidth: '85%',
            marginTop: '.6em',
            backgroundColor: 'WhiteSmoke',
          }}>
        <Box
          sx={{
              width: '90%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
        >
           <Typography variant="h6">DKIM</Typography>
              <MouseOverPopover
                tekst="Domain Keys Identified Mail (DKIM) is an email authentication technique that verifies an email was sent and authorized by the domain owner."
              /></Box>
         <Box
            sx={{
              marginY: 1,
              backgroundColor: '#e0e0e0',
              borderRadius: 1,
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                height: 30,
                color: '#fff',
                textAlign: 'center',
                lineHeight: '30px',
                ...barStyles.bar_2,
              }}
            >
                <b>{dkimScore}%</b>
               </Box>
          </Box>
            <Typography>
              DKIM: <b>{DKIM}</b>
            </Typography>
            <Typography>{dkimText}</Typography>
            </Paper>

      {/* DMARC Card */}
      
      <Paper  sx={{
            padding: 2,
            maxWidth: '85%',
            marginTop: '.6em',
            backgroundColor: 'WhiteSmoke',
          }}>
        <Box
          sx={{
              width: '90%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
        >
          <Typography variant="h6">DMARC</Typography>
              <MouseOverPopover tekst="Domain-based Message Authentication, Reporting, and Conformance (DMARC) uses SPF and DKIM to authenticate an email."/>
        </Box>
          <Box
            sx={{
              marginY: 1,
              backgroundColor: '#e0e0e0',
              borderRadius: 1,
              overflow: 'hidden',
            }}
          >
            <Box
              sx={{
                height: 30,
                color: '#fff',
                textAlign: 'center',
                lineHeight: '30px',
                ...barStyles.bar_3,
              }}
            >
                <b>{dmarcScore}%</b>
                             </Box>
          </Box>
            <Typography>
              DMARC: <b>{DMARC}</b>
            </Typography>
            <Typography>{dmarcText}</Typography>
          </Paper>

      {/* Evaluation Card */}
     
      {/* Sender's IP Card */}

      <Paper  sx={{
            padding: 2,
            maxWidth: '85%',
            marginTop: '.6em',
            backgroundColor: 'WhiteSmoke',
          }}>
        <Box
          sx={{
              width: '90%',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
        >
         <Typography variant="h6">Sender's IP</Typography>
              <MouseOverPopover
                tekst="Check if the IP address of the email sender is blacklisted."
              /></Box>
           <Box
            sx={{
              marginY: 1,
              backgroundColor: '#e0e0e0',
              borderRadius: 1,
              overflow: 'hidden',
            }}
          >
           <Typography>
              Sender's IP: <b>{ipAdr}</b>
            </Typography>
            </Box>
         
            {ipURL && (
              <Button
                variant="contained"
                color="primary"
                href={ipURL}
                target="_blank"
                sx={{ marginTop: 1 }}
              >
                Check IP
              </Button>
            )}
        </Paper>
      
    </Box>

);
};

Authentication.propTypes = {
  onAuthScore: PropTypes.func,
};

export default Authentication;
