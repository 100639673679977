// Import necessary libraries
import React, { useEffect, useState } from 'react';
import Plotly from 'plotly.js-dist';

console.log('Imports complete.');

const ViolinPlot = () => {
    const [df, setDf] = useState([]);

    useEffect(() => {
        const fetchPlotData = async () => {
            try {
                const res = await fetch('/api/features');
                const data = await res.json();

                // Remove rows with NaN values
                const filteredData = data.filter(d => Object.values(d).every(v => v !== null && v !== undefined));
                setDf(filteredData);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchPlotData();
    }, []);

    useEffect(() => {
        if (df.length > 0) {
            const depVar = 'dld_domain';
            const uniqueDepVar = Array.from(new Set(df.map(d => d[depVar])));
            const features = Object.keys(df[0]).filter(key => key !== depVar);

            console.log(features);
            console.log(`Number of features: ${features.length}`);

            features.forEach(feature => {
                console.log(feature);
                createViolinPlot(df, depVar, feature, uniqueDepVar);
            });

            // Add specific features to a subplot for ease of use
            let featureList = ['Entropy_URL', 'Path_LongestWordLength', 'sub_Directory_LongestWordLength', 'avgpathtokenlen'];
            createSubplots(df, depVar, featureList, uniqueDepVar, 2, 2, 'Distribution Plots of Selected Multi-class Features');
        }
    }, [df]);

    return (
        <div>
            <div id="violinPlot" style={{ marginBottom: '50px' }}></div>
            <div id="subplots"></div>
        </div>
    );
};

function createViolinPlot(df, depVar, feature, uniqueDepVar) {
    const data = uniqueDepVar.map(value => {
        return {
            type: 'violin',
            x: df.filter(d => d[depVar] === value).map(d => d[depVar]),
            y: df.filter(d => d[depVar] === value).map(d => +d[feature]),
            name: value,
            box: {
                visible: true
            },
            meanline: {
                visible: true
            }
        };
    });

    const layout = {
        title: `Distribution Plot of '${feature}'`,
        width: 900,
        height: 600
    };

    Plotly.newPlot('violinPlot', data, layout);
}

function createSubplots(df, depVar, featureList, uniqueDepVar, nrows, ncols, title) {
    const data = [];

    featureList.forEach((feature, idx) => {
        uniqueDepVar.forEach(value => {
            data.push({
                type: 'violin',
                x: df.filter(d => d[depVar] === value).map(d => d[depVar]),
                y: df.filter(d => d[depVar] === value).map(d => +d[feature]),
                xaxis: `x${idx + 1}`,
                yaxis: `y${idx + 1}`,
                name: `${feature} (${value})`,
                box: {
                    visible: true
                },
                meanline: {
                    visible: true
                }
            });
        });
    });

    const layout = {
        title: title,
        grid: {rows: nrows, columns: ncols, pattern: 'independent'},
        width: 2400,
        height: 1600,
        showlegend: false
    };

    Plotly.newPlot('subplots', data, layout);
}

export default ViolinPlot;
