import React, { useEffect, useState } from 'react';
import { Paper, Typography, Box } from '@mui/material';
//import { Link } from 'react-router-dom';
import MouseOverPopover from './MouseOverPopover';
import PropTypes from 'prop-types';
import { useEmailContext } from './EmailContext';

const Sender = ({ onSenderScore }) => {
  const { selectedEmail } = useEmailContext();
  const displayName = selectedEmail?.displayName || '';
  const senderEmailDisplayName = selectedEmail?.senderEmailDisplayName || '';
  //const mailHeaders = selectedEmail?.mailHeaders || '';

 // const [ipAdr, setIpAdr] = useState('');
 // const [ipURL, setIpURL] = useState('');
  const [senderScore, setSenderScore] = useState(0);
  const [nameSimilarity, setNameSimilarity] = useState('');
  const [totVal, setTotVal] = useState(0);
  const [barStyles, setBarStyles] = useState({
    bar1: {},
    bar4: {},
  });

  useEffect(() => {
    if (senderEmailDisplayName && displayName) {
      compareDisplayNameAndEmailAddress(senderEmailDisplayName, displayName);
    }
  }, [senderEmailDisplayName, displayName]);

 // useEffect(() => {
 //   if (mailHeaders) {
 //     extractSenderIP(mailHeaders);
 //   }
//  }, [mailHeaders]);

  const compareDisplayNameAndEmailAddress = (senderEmailDisplayName, displayName) => {
    const senderNameLower = senderEmailDisplayName.toLowerCase();
    const recipientNameLower = displayName.toLowerCase();

    // Direct match
    if (senderNameLower === recipientNameLower) {
      setSenderScore(100);
      setNameSimilarity('Exact match with recipient name');
      updateProgressBar(100, 'bar1');
      totEvaluation(100);
      return;
    }

    const score = calculateNameSimilarityScore(senderNameLower, recipientNameLower);
    setScoreState(score);
  };

  const calculateNameSimilarityScore = (senderEmailDisplayName, displayName) => {
    let score = 0;

    const normalize = (name) =>
      name
        .replace(/\.[^/.]+$/, '') // Remove domain if present
        .replace(/[^\w\s]|_/g, '') // Remove punctuation and special characters
        .replace(/\s+/g, ' ') // Replace multiple spaces with single space
        .trim() // Trim leading and trailing spaces
        .split(' ')
        .filter(Boolean); // Split into words and filter out empty strings

    const senderWords = normalize(senderEmailDisplayName);
    const recipientWords = normalize(displayName);

    const allWords = new Set([...senderWords, ...recipientWords]);
    const matchingWords = senderWords.filter((word) => recipientWords.includes(word));

    score = (matchingWords.length / allWords.size) * 100;

    return Math.min(score, 100);
  };

  const setScoreState = (score) => {
    let similarity = '';

    if (score >= 90) similarity = 'Very high similarity';
    else if (score >= 75) similarity = 'High similarity';
    else if (score >= 50) similarity = 'Moderate similarity';
    else if (score >= 25) similarity = 'Low similarity';
    else similarity = 'No significant similarity';

    setSenderScore(Math.round(score));
    setNameSimilarity(similarity);
    updateProgressBar(score, 'bar1');
    totEvaluation(score);
  };

 //const extractSenderIP = (mailHeaders) => {
 //   const ipRegex = /\b\d{1,3}(?:\.\d{1,3}){3}\b/;
 //   const ipMatch = mailHeaders.match(ipRegex);

 //   if (ipMatch) {
 //     const ipAddress = ipMatch[0];
 //    const url = `https://mxtoolbox.com/SuperTool.aspx?action=blacklist%3a${ipAddress}&run=toolpage`;
  //    setIpAdr(ipAddress);
  //    setIpURL(url);
  //  } else {
  //    setIpAdr('IP address not found');
  //  }
 // };

  const updateProgressBar = (value, barKey) => {
    let backgroundColor = '';

    if (value >= 75) backgroundColor = 'green';
    else if (value >= 50) backgroundColor = 'orange';
    else if (value >= 25) backgroundColor = 'orangered';
    else backgroundColor = 'red';

    setBarStyles((prevStyles) => ({
      ...prevStyles,
      [barKey]: { width: `${value}%`, backgroundColor },
    }));
  };

  const totEvaluation = (score) => {
    const roundedScore = Math.round(score);
    setTotVal(roundedScore);
    updateProgressBar(roundedScore, 'bar4');

    if (onSenderScore) {
      onSenderScore(roundedScore);
    }
  };

  return (
    <Box sx={{  padding: '1em', marginTop: '1em' }}>
      
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        {/* Sender Persona */}
        <Paper
          sx={{
            padding: 2,
            maxWidth: '85%',
            marginTop: '.6em',
            backgroundColor: 'WhiteSmoke',
          }}
        >
          <Box sx={{ width: '90%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">Sender-Receiver Similarity</Typography>
            <MouseOverPopover
              tekst="Compares the sender's display name with the recipient's display name and gives a score based on similarities."
            />
          </Box>
          <Box
            sx={{
              marginY: 1,
              backgroundColor: '#e0e0e0',
              borderRadius: 1,
              overflow: 'hidden',
              height: 30,
            }}
          >
            <Box
              sx={{
                height: '100%',
                color: '#fff',
                textAlign: 'center',
                lineHeight: '30px',
                ...barStyles.bar1,
              }}
            >
              <b>{senderScore}%</b>
            </Box>
          </Box>
          <Typography>
            Recipient Display Name: <b>{displayName}</b>
          </Typography>
          <Typography>
            Sender Display Name: <b>{senderEmailDisplayName}</b>
          </Typography>
          <Typography>
            Similarity: <b>{nameSimilarity}</b>
          </Typography>
        </Paper>

        {/* Sender's IP */}
        

        {/* Total Evaluation */}
       
      </Box>
      </Box>
  );
};

Sender.propTypes = {
  onSenderScore: PropTypes.func,
};

export default Sender;
