// src/components/NotFoundPage.js
import React from 'react';
import { Typography, Container } from '@mui/material';

const NotFoundPage = () => (
  <Container>
    <Typography variant="h4" color="error" gutterBottom>
      404 - Page Not Found
    </Typography>
    <Typography variant="body1">
      The page you are looking for does not exist.
    </Typography>
  </Container>
);

export default NotFoundPage;
