import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Typography,
  Button,
  Grid2,
  LinearProgress,
} from '@mui/material';
import { Link } from 'react-router-dom';
import MouseOverPopover from './MouseOverPopover';
import PropTypes from 'prop-types';
import { useEmailContext } from './EmailContext';

const Links = ({ onLinkScore }) => {
  // Individual state variables
  const [linkEncodeScore, setLinkEncodeScore] = useState(0);
  const [linksHttpsScore, setLinksHttpsScore] = useState(0);
  const [linkRedirectScore, setLinkRedirectScore] = useState(0);
  const [linkDuplicateScore, setLinkDuplicateScore] = useState(0);
  const [linkSimilarityScore, setLinkSimilarityScore] = useState(0);
  const [linkArray, setLinkArray] = useState([]);
  const [totVal, setTotVal] = useState(0);
  const [numEncoding, setNumEncoding] = useState(0);
  const [linksHTTPS, setLinksHTTPS] = useState(0);
  const [numRedirect, setNumRedirect] = useState(0);
  const [numLinks, setNumLinks] = useState(0);
  const [duplicateLinks, setDuplicateLinks] = useState(0);

  const { selectedEmail } = useEmailContext();
  // Destructure selectedEmail prop
  const emailBody = selectedEmail?.emailBody || '';
  const senderEmailDisplayName = selectedEmail?.senderEmailDisplayName || '';
  const emailAddress = selectedEmail?.emailAddress || '';
  const fromEmailAddress = selectedEmail?.fromEmailAddress || '';

  useEffect(() => {
    if (linkSimilarityScore && linkEncodeScore && linksHttpsScore && linkRedirectScore && linkDuplicateScore) {
      calculateTotalEvaluation(linkSimilarityScore, linkEncodeScore, linksHttpsScore, linkRedirectScore, linkDuplicateScore);
    }
    if (emailAddress && emailBody && senderEmailDisplayName) {
      extractAndCleanLinks(emailBody, senderEmailDisplayName, emailAddress);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromEmailAddress, emailAddress, emailBody, senderEmailDisplayName]);

  // Compare sender and receiver names
  //const compareName = (fromEmail, toEmail) => {
 //   if (fromEmail === toEmail) {
 //     setDefaultState('The sender and receiver are the same person.');
  //   }
  //};

  // Extract and clean links from email body
  const extractAndCleanLinks = (emailBody) => {
    // Regex to match http and https links
    const regex = /(https?:\/\/[^\s"']+)/g;
    // Extract all links
    const extractedLinks = emailBody.match(regex) || [];
  
    // If no links found, set default state
    if (extractedLinks.length === 0) {
      return [];
    }
  
    // Process each link to decode, check, and clean if it contains '___'
    const processedLinks = extractedLinks.map((link) => {
      const parts = link.split('___');
      // If the link contains at least two '___', keep only the content between the first and second
      const cleanedLink = parts.length >= 3 ? parts[1] : link;
      // Decode the link
      return decodeURIComponent(cleanedLink);
    });
  
    // Remove duplicates using a Set
    const uniqueLinks = [...new Set(processedLinks)];
  
    // Set unique processed links in the state
    setLinkArray(uniqueLinks);
    analyzeLinks(uniqueLinks);
    return uniqueLinks;
  };

  // Analyze links to calculate various scores
  const analyzeLinks = (processedLinks) => {
    const numLinks = processedLinks.length;
    let numEncoding = 0;
    let linksHTTPS = 0;
    let numRedirect = 0;

    processedLinks.forEach((linked) => {
      try {
        const parsedLink = new URL(linked);
        if (parsedLink.href !== decodeURIComponent(parsedLink.href)) numEncoding++;
        if (parsedLink.protocol === 'https:') linksHTTPS++;
        if (parsedLink.pathname.includes('redirect')) numRedirect++;
      } catch {
        // Ignore invalid URLs
      }
    setNumEncoding(numEncoding);
    setLinksHTTPS(linksHTTPS);
    setNumRedirect(numRedirect);
    

    
    

    
    const encodeScore = numLinks > 0 ? Math.max(0, 100 - (numEncoding / numLinks) * 100) : 100;
    const httpsScore = numLinks > 0 ? (linksHTTPS / numLinks) * 100 : 100;
    const redirectScore = numLinks > 0 ? 100 - (numRedirect / numLinks) * 100 : 100;
 
    setNumLinks(numLinks);
    setLinkEncodeScore(encodeScore);
    setLinksHttpsScore(httpsScore);
    setLinkRedirectScore(redirectScore);
   
    const duplicateScore = calculateDuplicateScore(processedLinks, numLinks);
    setLinkDuplicateScore(duplicateScore);

    // Calculate link similarity score
    const similarityScore = calculateLinkSimilarity(processedLinks, senderEmailDisplayName, emailAddress);
    setLinkSimilarityScore(similarityScore);
  });
  };

  // Calculate duplicate link score
  const calculateDuplicateScore = (processedLinks, numLinks) => {
    const uniqueLinks = new Set(processedLinks);
    const duplicateLinkCount = numLinks - uniqueLinks.size;
    setDuplicateLinks(duplicateLinkCount); // Save the count to state
    return numLinks > 0 ? 100 - (duplicateLinkCount / numLinks) * 100 : 100;
  };
  
  // Calculate link similarity score
  const calculateLinkSimilarity = (processedLinks, senderEmailDisplayName, emailAddress) => {
    let score = 0;
    const allWords = senderEmailDisplayName.toLowerCase().split(' ').filter((word) => word.length > 1);

    allWords.forEach((word) => {
      processedLinks.forEach((link) => {
        if (link.toLowerCase().includes(word)) score += 20; // Adjust as necessary
      });
    });

    // Ensure the score doesn't exceed 100
    return Math.min(100, score);
  };

  // Calculate total evaluation score
  const calculateTotalEvaluation = () => {
    // Calculate total score
    const totalValue =
      (linkSimilarityScore +
        linkEncodeScore +
        linksHttpsScore +
        linkRedirectScore +
        linkDuplicateScore) /
      2.6;
  
    // Round the score and cap at 100
    const roundedScore = Math.min(100, Math.round(totalValue));
  
    // Set the total value
    setTotVal(roundedScore);
  
    // Trigger callback if available
    if (onLinkScore) {
      onLinkScore(totVal);
    }
  };

  // Extract and analyze links whenever `selectedEmail` changes
 
  const getColor = (value) => {
    let backgroundColor = '';
    if (value >= 75) return 'green';
    else if (value >= 51) backgroundColor = 'orange';
    else if (value >= 26) backgroundColor = 'OrangeRed';
    else backgroundColor = 'red';
  
    return {
      width: `${value}%`, // Set width based on the score value
      backgroundColor,
    };
};

  // Your JSX return block (corrected state references)
  return (
    <>


      <Grid2 container spacing={2}>
        {/* Link-Sender Relation */}
        <Grid2 item xs={12} sm={6}>
   
        <Card sx={{ width: '100%', marginBottom: '1em', display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardHeader
              title="Link-Sender Relation"
              action={
                <MouseOverPopover
                  tekst="Legitimate websites usually have their company name in their display name, email domain, and links. If not, it is probably someone trying to imitate the company."
                />
              }
            />
            <CardContent>
               <LinearProgress
                variant="determinate"
                value={linkSimilarityScore}
                sx={{
                  height: 10,
                  borderRadius: 5,
                  backgroundColor: 'lightgrey',
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: getColor(linkSimilarityScore),
                  },
                }}
              />
                 <Typography variant="body2">
                <b>{linkSimilarityScore}%</b>
              </Typography>
                </CardContent>
              <Typography>
                Sender Display Name: <b>{senderEmailDisplayName}</b>
              </Typography>
              <Typography>
                From Email Address: <b>{fromEmailAddress}</b>
              </Typography>
             </Card>
        </Grid2>
        {/* URL Encoding */}
        <Grid2 item xs={12} sm={6}>
   
   <Card sx={{ width: '100%', marginBottom: '1em', display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardHeader
              title="URL Encoding"
              action={
                <MouseOverPopover
                  tekst="URL encoding replaces unsafe ASCII characters with a % followed by two hexadecimal digits. This makes it hard to read and can be used to hide the true location of a link."
                />
              }
            />
            <CardContent>
              <LinearProgress
                  variant="determinate"
                  value={linkEncodeScore}
                  sx={{
                    height: 10,
                    borderRadius: 5,
                    backgroundColor: 'lightgrey',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: getColor(linkEncodeScore),
                    },
                  }}
                />
                <Typography variant="body2">
                  <b>{linkEncodeScore}%</b>
                </Typography>
              <Typography>
                <b>{numEncoding}</b> of <b>{numLinks}</b> link(s) contain
                URL encoding
              </Typography>
            </CardContent>
          </Card>
        </Grid2>

        {/* Link Security */}
        <Grid2 item xs={12} sm={6}>
   
   <Card sx={{ width: '100%', marginBottom: '1em', display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardHeader
              title="Link Security"
              action={
                <MouseOverPopover
                  tekst="Hypertext Transfer Protocol Secure (HTTPS) is an extension of HTTP. It is used for secure communication over a network and is widely used on the Internet. If a link does not use HTTPS, it is seen as unsafe."
                />
              }
            />
            <CardContent>
                <LinearProgress
                  variant="determinate"
                  value={linksHttpsScore}
                  sx={{
                    height: 10,
                    borderRadius: 5,
                    backgroundColor: 'lightgrey',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: getColor(linksHttpsScore),
                    },
                  }}
                />
                <Typography variant="body2">
                  <b>{linksHttpsScore}%</b>
                </Typography>
              <Typography>
                <b>{linksHTTPS}</b> of <b>{numLinks}</b> link(s) have HTTPS
                (SSL)
              </Typography>
            </CardContent>
          </Card>
        </Grid2>

        {/* Redirecting Links */}
        <Grid2 item xs={12} sm={6}>
   
        <Card sx={{ width: '100%', marginBottom: '1em', display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardHeader
              title="Redirecting Links"
              action={
                <MouseOverPopover
                  tekst="Some links contain redirection. This means that instead of the link taking you to a legitimate webpage, the link redirects to somewhere else."
                />
              }
            />
            <CardContent>
                <LinearProgress
                  variant="determinate"
                  value={linkRedirectScore}
                  sx={{
                    height: 10,
                    borderRadius: 5,
                    backgroundColor: 'lightgrey',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: getColor(linkRedirectScore),
                    },
                  }}
                />
                <Typography variant="body2">
                  <b>{linkRedirectScore}%</b>
                </Typography>
              <Typography>
                <b>{numRedirect}</b> of <b>{numLinks}</b> link(s) contain
                redirection
              </Typography>
              <Typography>
                Example of redirection in URL: /redirect?z=
              </Typography>
            </CardContent>
          </Card>
        </Grid2>

        {/* Duplication of Links */}
        <Grid2 item xs={12} sm={6}>
   
   <Card sx={{ width: '100%', marginBottom: '1em', display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardHeader
              title="Duplication of Links"
              action={
                <MouseOverPopover
                  tekst="Most phishing emails will contain duplicate URLs. This is sometimes seen in legitimate emails as well. Therefore, the score is weighted less significantly in the evaluation."
                />
              }
            />
            <CardContent>
                <LinearProgress
                  variant="determinate"
                  value={linkDuplicateScore}
                  sx={{
                    height: 10,
                    borderRadius: 5,
                    backgroundColor: 'lightgrey',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: getColor(linkDuplicateScore),
                    },
                  }}
                />
                <Typography variant="body2">
                  <b>{linkDuplicateScore}%</b>
                </Typography>
              <Typography>
                <b>{duplicateLinks}</b> of <b>{numLinks}</b> link(s) are
                duplicates
              </Typography>
            </CardContent>
          </Card>
        </Grid2>

        {/* Total Evaluation */}
        <Grid2 item xs={12} sm={6}>
   
        <Card sx={{ width: '100%', marginBottom: '1em', display: 'flex', flexDirection: 'column', height: '100%' }}>
            <CardHeader
              title="Evaluation"
              action={
                <MouseOverPopover
                  tekst="A total evaluation of the links found in the email. The percent is an average of all attributes, with URL encoding, redirection, and duplication rated less significantly than link-sender relation and link security."
                />
              }
            />
            <CardContent>
                <LinearProgress
                  variant="determinate"
                  value={totVal}
                  sx={{
                    height: 10,
                    borderRadius: 5,
                    backgroundColor: 'lightgrey',
                    '& .MuiLinearProgress-bar': {
                      backgroundColor: getColor(totVal),
                    },
                  }}
                />
                <Typography variant="body2">
                  <b>{totVal}%</b>
                </Typography>

              <Typography>
                Total evaluation of link(s) in this email
              </Typography>
            </CardContent>
          </Card>
        </Grid2>

        <Grid2 >
        <Card>
        <CardHeader title="Processed and Decoded Links:"/>
              <CardContent sx={{overflowY: 'auto' }}>
                {linkArray.map((item, index) => (
                  <ul key={index}>
                    <b>{item}</b>
                  </ul>
                ))}
            </CardContent>
            </Card>
        </Grid2>
      </Grid2>

      <Button
        variant="contained"
        color="primary"
        sx={{ marginTop: 2 }}
        component={Link}
        to="/"
      >
        Go back
      </Button>
    </>
  );
};

Links.propTypes = {
  onLinkScore: PropTypes.func,
};

export default Links;
